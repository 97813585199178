$(document).on('found_variation', '.variations_form', (event, variation) => {
  const $tensioners = $(event.currentTarget).find('.woocommerce-variation-tensioners');
  variation.tensioners ? $tensioners.html(variation.tensioners).slideDown('fast') : $tensioners.slideUp('fast', () => $tensioners.html(''));
  $tensioners.find('[name=tensioner_quantity]').val($tensioners.data('last_quantity') || 1);
  $tensioners.find(`[data-parent=${$tensioners.data('last_tensioner')}] [type=radio]`).click();
});

$(document).on('hide_variation', '.variations_form', event => {
  const $tensioners = $(event.currentTarget).find('.woocommerce-variation-tensioners');
  $tensioners.slideUp('fast', () => $tensioners?.html(''));
});

$(document).on('change', '.tensioner_radio [type=radio]', event => {
  const $radio = $(event.currentTarget).closest('.tensioner_radio');
  const $quantity = $radio.find('[name=tensioner_quantity]');
  const $tensioners = $radio.closest('.woocommerce-variation-tensioners');
  $radio.find('.quantity-wrap').show().find('input').prop('disabled', false);
  $radio.siblings().find('.quantity-wrap').hide().find('input').prop('disabled', true);
  $tensioners.data('last_tensioner', $radio.data('parent') || null);
  $tensioners.data('last_quantity', $quantity.val());
});

$(document).on('change', '.tensioner_radio [name=tensioner_quantity]', event => {
  const $radio = $(event.currentTarget).closest('.tensioner_radio');
  const $quantity = $radio.find('[name=tensioner_quantity]');
  const $tensioners = $radio.closest('.woocommerce-variation-tensioners');
  $tensioners.data('last_quantity', $quantity.val());
  $tensioners.find('[name=tensioner_quantity]').val($quantity.val());
});

$(document).on('click', '.tensioner_radio', event => {
  const $input = $(event.currentTarget).find('[type=radio]');
  if (!event.target.classList.toString().startsWith('woosq-btn')) {
    $input.prop('checked', true).trigger('change');
  }
});
