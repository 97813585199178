const $video = $('.video');
const $videoCard = $('.video_card');
const $videoBox = $('.video_box');

$videoCard.click(function () {
  let tabId = $(this).data('video');
  showVideo(tabId);
  $('html, body').animate({
    scrollTop: $videoBox.offset().top - 20,
  }, 500);
});

showVideo('video0');

function showVideo(tabId) {
  $video.hide();
  $videoCard.removeClass('active-video');
  $(`#${tabId}`).show();
  $(`[data-video="${tabId}"]`).addClass('active-video');
}
