let $coupon = $('.coupon-box');
$coupon.on('click', (e) => {
  const $this = $(e.currentTarget);
  if ($this.attr('data-name') === 'Click To Copy Coupon') {
    const textToCopy = $this.data('coupon');
    const tempInput = $('<input>');
    $('body').append(tempInput);
    tempInput.val(textToCopy).select();
    document.execCommand('copy');
    tempInput.remove();

    $this.attr('data-name', 'Copied');
    setTimeout(() => {
      $this.attr('data-name', 'Click To Copy Coupon');
    }, 2000);
  }
});
