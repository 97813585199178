import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules'
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const supportSlider  = new Swiper('.header-slider',{
  loop: true,
  freeMode: true,
  slidesPerView: 'auto',
  speed: 11000,
  autoplay: {
    delay: 0,
    pauseOnMouseEnter: false,
    disableOnInteraction: false,
    waitForTransition: true,
    stopOnLastSlide: false,
  },
})
