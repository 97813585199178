import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper/modules';

Swiper.use([Navigation, Autoplay, Scrollbar, Pagination]);

const reviewsCat = new Swiper('.reviews-cat-swiper', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 30,
  autoHeight: true,
  navigation: {
    nextEl: '.reviews-cat-button-next',
    prevEl: '.reviews-cat-button-prev',
  },
  breakpoints: {
    1200: {
      slidesPerView: 2
    }
  }
});
