import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper/modules';

Swiper.use([Navigation, Autoplay, Scrollbar, Pagination]);

const imageSwiper = new Swiper('.image-swiper', {
  loop: false,
  slidesPerView: 1,
  spaceBetween: 30,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    hide: false,
  },
  navigation: {
    nextEl: '.image-swiper-button-next',
    prevEl: '.image-swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    type: 'custom',
    renderCustom: function (swiper, current, total) {
      let realTotal = ('0' + total).slice(-2);
      return '<span class="swiper-number">01</span>' + '<span class="swiper-number">' + realTotal + '</span>';
    },
  },
});
