$(document).on('click', '.woocommerce-product-gallery .woocommerce-product-gallery__image a, .woocommerce-product-gallery .zoomImg', event => {
    const $this = $(event.currentTarget)
    if($(window).outerWidth() >= 1024) {
      if ($this.hasClass('zoomImg')) {
        $('.woocommerce-product-gallery__trigger').click()
      } else {
        if($('.flex-control-nav').length !== 0 ) {
          $('.woocommerce-product-gallery__trigger').click()
        }
      }
    }
})
